import { Icons } from 'components/icons';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { initialValues, validationSchema, model, PoaSignedFormValues } from './config';
import s from './styles.module.scss';
import { InputField } from 'components/common';
import { Button } from 'components/ui/Button';
import { useParams } from 'react-router-dom';
import { useGetPoaDraftQuery, usePoaSendEmailToAuthorityMutation } from 'store/api/poa';
import { AuthorityInfo, RepresetativeInfo } from 'store/api/types';
import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useHandleMutation } from 'utils/handleMutation';
import { selectCurrentCertificate } from 'store/reducers/certificates';
import { useSelector } from 'react-redux';

export function PoaSigned() {
  const alert = useAlert();
  const { id } = useParams();
  const { t } = useTranslation();

  const currentCertificate = useSelector(selectCurrentCertificate());
  const { data: draft } = useGetPoaDraftQuery({ id: id ?? '' }, { skip: !id });
  const [sendEmail, mutation] = usePoaSendEmailToAuthorityMutation();

  const onSuccess = useCallback(() => {
    alert?.success('Сообщение успешно отправлено!');
  }, [alert]);

  const onError = useCallback(() => {
    alert?.success('Ошибка при отравке емейла');
  }, [alert]);

  useHandleMutation({ ...mutation, onSuccess, onError });

  const representative: RepresetativeInfo = JSON.parse(
    draft?.representative?.rawUserInfo ?? '{}',
  );
  const authority: AuthorityInfo = JSON.parse(draft?.authority?.rawUserInfo ?? '{}');

  const onSubmit = async (
    values: PoaSignedFormValues,
    { resetForm }: FormikHelpers<PoaSignedFormValues>,
  ) => {
    const { email } = values;
    if (email) {
      sendEmail({ email, cert: currentCertificate?.Base64RawData ?? '' });
    }
    resetForm();
  };

  return (
    <div className={s.root}>
      <div className={s.content}>
        <div className={s.firstBlock}>
          <div className={s.title}>
            {t(
              'Contracteum ensures the confidentiality of transmitted information and guarantees the legal significance of transactions',
            )}
          </div>
        </div>

        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ dirty, isSubmitting, submitForm, errors }) => (
            <div className={s.secondBlock}>
              <div className={s.info}>
                <div className={s.infoHeader}>
                  <Icons.SuccessMark />
                  <span className={s.description}>
                    {t('Thank you, the power of attorney has been successfully created!')}
                  </span>
                </div>

                <div className={s.fieldsWrapper}>
                  <InputField className={s.input} {...model.email} />
                  <Button
                    type="primary"
                    onClick={submitForm}
                    text={t('Отправить')}
                    disabled={!dirty || isSubmitting || !!Object.values(errors)?.length}
                  />
                </div>

                <span className={s.description}>
                  {t(
                    `Теперь ${representative?.surname ?? ''} ${representative?.name ?? ''} ${
                      representative?.paternal_name ?? ''
                    }  может зарегистрировать ${
                      authority?.organization_name ?? ''
                    }. Спасибо, что выбрали Контрактиум! Теперь ваши сделки в цифре будут быстрыми и безопасными.`,
                  )}
                </span>
              </div>
            </div>
          )}
        </Formik>
      </div>

      <div className={s.bottom}></div>
    </div>
  );
}
