import { ReactComponent as InfoIcon } from 'assets/images/information.svg';
import { RadioGroupField } from 'components/fields/radio-group-field/select-filed';
import { PoaCreateForm } from 'components/forms/poa/create/PoaCreateForm';
import { Button } from 'components/ui/Button';
import { AppRoutes } from 'config/routes';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
  PoaCreateFromValues,
  initialValues,
  model,
  validationSchema,
} from 'components/forms/poa/create/config';

import { useCallback, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import { useCreatePoaDraftMutation, useGetPoaDraftQuery } from 'store/api/poa';
import { useUpdatePoaRepresentativeMutation } from 'store/api/poaUsers';
import { RepresetativeInfo } from 'store/api/types';
import { selectInviteId } from 'store/reducers/invite.conteragent';
import { prepareDate } from 'utils/date';
import { useHandleMutation } from 'utils/handleMutation';
import s from './styles.module.scss';

export function PoaCreate() {
  const { id: draftId } = useParams<{ id: string }>();

  const alert = useAlert();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { Auth } = AppRoutes;
  const inviteId = useSelector(selectInviteId());
  const [createDraft, createDraftMutation] = useCreatePoaDraftMutation();
  const [updateUser, updateUserMutation] = useUpdatePoaRepresentativeMutation();
  const isLoading = updateUserMutation?.isLoading;

  const { data: draft } = useGetPoaDraftQuery(
    { id: draftId ?? '', format: null },
    { skip: !draftId },
  );

  useEffect(() => {
    if (!inviteId) {
      navigate(Auth.Home + Auth.Login);
      alert.error('Приглашение не найдено');
    }
  }, [inviteId]);

  useEffect(() => {
    if (!draftId) {
      createDraft({ inviteId });
    }
  }, []);

  const onSuccessUpdateUser = useCallback(
    (id: string) => {
      navigate(`${Auth.Home}${Auth.PoaRequest}/${draftId}`);
    },
    [navigate],
  );

  const onSuccess = useCallback(
    (id: string) => {
      navigate(`${Auth.Home}${Auth.PoaCreate}/${id}`);
    },
    [navigate],
  );

  useHandleMutation({ ...createDraftMutation, onSuccess });
  useHandleMutation({ ...updateUserMutation, onSuccess: onSuccessUpdateUser });

  const onSubmit = async (values: PoaCreateFromValues) => {
    const representativeDefaultValues: RepresetativeInfo = JSON.parse(
      draft?.representative?.rawUserInfo ?? '{}',
    );

    updateUser({
      userId: draft?.representative.id ?? '',
      userType: draft?.representative?.userType ?? '',

      data: JSON.stringify({
        ...representativeDefaultValues,
        kpp: values?.kpp,
        name: values?.name,
        doc_code: '000000',
        email: values?.email,
        snils: values?.snils,
        surname: values?.surname,
        inn_natural_person: values?.innFl,
        paternal_name: values?.middleName,
        doc_type: values?.typeIdentityDocument,
        birth_date: prepareDate(values?.birthdate),
        doc_number: values?.documentSeriesAndNumber,
        citizenship_flag: values?.citizenship ?? 'ru',
        doc_issuer: values?.nameAuthorityIssuedDocument,
        doc_issue_date: prepareDate(values?.dateIssueDocument),
      }),
    });
  };

  return (
    <div className={s.root}>
      <div className={s.content}>
        <div className={s.firstBlock}>
          <div className={s.subtitle}>
            {t(
              'Contracteum ensures the confidentiality of transmitted information and guarantees the legal significance of transactions',
            )}
          </div>
        </div>

        <div className={s.secondBlock}>
          <div className={s.wrapperDesc}>
            <span className={s.description}>
              {t(
                'To register your company, we need to verify your authority using a machine-readable power of attorney (MPOA)',
              )}
            </span>
            <div className={s.wrapperIcon}>
              <InfoIcon />
            </div>
          </div>

          <Formik
            validateOnMount
            enableReinitialize
            onSubmit={onSubmit}
            initialValues={initialValues({ draft })}
            validationSchema={validationSchema(t)}
          >
            {({ errors, isValid, values, isSubmitting, submitForm }) => (
              <>
                <RadioGroupField {...model.creationOptions} />
                <div className={s.wrapperForm}>
                  <PoaCreateForm values={values} />
                </div>
                <div className={s.buttonContainer}>
                  <Button
                    type="primary"
                    className={s.button}
                    onClick={submitForm}
                    disabled={isSubmitting || !isValid || !!Object.values(errors)?.length}
                  >
                    {isLoading ? t('Loading...') : t('Create')}
                  </Button>
                </div>
              </>
            )}
          </Formik>
        </div>
      </div>

      <div className={s.bottom}></div>
    </div>
  );
}
