import { TFunction } from 'i18next';
import * as Yup from 'yup';

import { DatePickerMode } from 'components/common/date-picker/date-picker';
import { InputMasks } from 'components/common/input-field/input-field';
import dayjs from 'dayjs';
import { AuthorityInfo, PoaDraft, RepresetativeInfo } from 'store/api/types';
import { DefaultDateFormat } from 'utils/date';

export interface PoaCreateFromValues {
  inn: string;
  kpp: string;
  name: string;
  email: string;
  innFl: string;
  snils: string;
  surname: string;
  birthdate: string;
  middleName: string;
  citizenship: string;
  nameOrganization: string;
  dateIssueDocument: string;
  typeIdentityDocument: string;
  documentSeriesAndNumber: string;
  nameAuthorityIssuedDocument: string;
}

export const model = {
  inn: {
    type: 'text',
    required: true,
    name: 'inn',
    label: 'INN',
    mask: InputMasks.org9,
    disabled: true,
  },

  kpp: {
    type: 'text',
    required: true,
    name: 'kpp',
    label: 'KPP',
    mask: InputMasks.org9,
  },

  name: {
    type: 'text',
    required: true,
    placeholder: 'Иван',
    name: 'name',
    label: 'Name',
  },

  email: {
    type: 'email',
    label: 'E-mail',
    name: 'email',
    placeholder: 'Enter email',
    required: true,
  },

  innFl: {
    type: 'text',
    required: true,
    placeholder: '12345678912',
    name: 'innFl',
    label: 'INN FL',
    mask: InputMasks.org13,
  },

  snils: {
    type: 'text',
    required: true,
    placeholder: '1234567891',
    name: 'snils',
    label: 'SNILS',
    mask: InputMasks.org13,
  },

  surname: {
    type: 'text',
    required: true,
    placeholder: 'Иванов',
    name: 'surname',
    label: 'Surname',
  },

  middleName: {
    type: 'text',
    required: true,
    placeholder: 'Иванович',
    name: 'middleName',
    label: 'Middle name',
  },

  birthdate: {
    type: 'date',
    required: true,
    mode: DatePickerMode.SINGLE,
    name: 'birthdate',
    label: 'Date of birth',
  },

  nameOrganization: {
    type: 'text',
    required: true,
    name: 'nameCompany',
    label: 'Name company',
    disabled: true,
  },

  citizenship: {
    type: 'radio',
    required: true,
    name: 'citizenship',
    label: 'Citizenship',
    options: [
      { label: 'Citizen of the Russian Federation', value: 'ru' },
      { label: 'Foreign citizen', value: 'foreign' },
    ],
  },
  country: {
    required: true,
    name: 'country',
    label: 'Country',
    placeholder: 'Select from the list',
  },

  creationOptions: {
    type: 'radio',
    disabled: true,
    name: 'creationOptions',
    options: [
      { label: 'Download the MCHD', value: 'download' },
      { label: 'Create a MCHD', value: 'create' },
    ],
  },

  typeIdentityDocument: {
    label: 'Type of identity document',
    name: 'typeIdentityDocument',
    placeholder: 'Select from the list',
    options: [
      { label: 'Passport of a citizen of the Russian Federation', value: '21' },
      { label: 'Passport of a foreign citizen', value: '10' },
      { label: 'Birth certificate', value: '03' },
      { label: 'Military ticket', value: '07' },
      {
        label:
          'Certificate of consideration of the application for recognition of a person as a refugee on the territory of the Russian Federation on the merits',
        value: '11',
      },
      { label: 'Residence permit in the Russian Federation', value: '12' },
      { label: 'Refugee certificate', value: '13' },
      { label: 'Temporary residence permit in the Russian Federation', value: '15' },
      {
        label: 'Certificate of temporary asylum in the territory of the Russian Federation',
        value: '19',
      },
      { label: 'Identity card of a serviceman of the Russian Federation', value: '24' },
    ],
    required: true,
  },

  documentSeriesAndNumber: {
    type: 'text',
    required: true,
    placeholder: '1234567891',
    name: 'documentSeriesAndNumber',
    label: 'Document series and number',
  },

  dateIssueDocument: {
    type: 'date',
    required: true,
    mode: DatePickerMode.SINGLE,
    name: 'dateIssueDocument',
    label: 'Date of issue of the document',
  },

  nameAuthorityIssuedDocument: {
    label: 'Name of the authority that issued the document',
    name: 'nameAuthorityIssuedDocument',
    required: true,
  },
};

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    [model.kpp.name]: Yup.number().required('This field is required'),
    [model.inn.name]: Yup.number().required('This field is required'),
    [model.name.name]: Yup.string().required('Name is required'),
    [model.email.name]: Yup.string()
      .required('This field is required')
      .email(t('Invalid email address entered!')),
    [model.surname.name]: Yup.string().required('Surname is required'),
    [model.middleName.name]: Yup.string().required('MiddleName is required'),
    [model.innFl.name]: Yup.number().required('This field is required'),
    [model.snils.name]: Yup.number().required('This field is required'),
    [model.dateIssueDocument.name]: Yup.string().required('This field is required'),
    [model.typeIdentityDocument.name]: Yup.string().required('This field is required'),
    [model.birthdate.name]: Yup.string().required('This field is required'),
    [model.citizenship.name]: Yup.string().required('Citizenship is required'),
    [model.nameAuthorityIssuedDocument.name]: Yup.string().required('This field is required'),
    [model.documentSeriesAndNumber.name]: Yup.number().required(
      'Document series and number is required',
    ),
  });

export const initialValues = ({ draft }: { draft: PoaDraft | undefined }) => {
  const authority: AuthorityInfo = JSON.parse(draft?.authority?.rawUserInfo ?? '{}');
  const representative: RepresetativeInfo = JSON.parse(
    draft?.representative?.rawUserInfo ?? '{}',
  );

  return {
    [model.creationOptions.name]: 'create',

    [model.inn.name]: authority?.inn_legal_entity,
    [model.kpp.name]: authority?.kpp,
    [model.nameOrganization.name]: authority?.organization_name,

    [model.name.name]: representative?.name,
    [model.email.name]: representative?.email,
    [model.snils.name]: representative?.snils,
    [model.surname.name]: representative?.surname,
    [model.innFl.name]: representative.inn_natural_person,
    [model.middleName.name]: representative.paternal_name,
    [model.citizenship.name]: representative.citizenship_flag,
    [model.typeIdentityDocument.name]: representative.doc_type,
    [model.documentSeriesAndNumber.name]: representative.doc_number,
    [model.nameAuthorityIssuedDocument.name]: representative.doc_issuer,
    [model.birthdate.name]: representative.birth_date
      ? dayjs(representative.birth_date).format(DefaultDateFormat)
      : null,
    [model.dateIssueDocument.name]: representative.doc_issue_date
      ? dayjs(representative.doc_issue_date).format(DefaultDateFormat)
      : null,
  } as unknown as PoaCreateFromValues;
};
