import cx from 'classnames';
import { HeaderPage } from 'components/header-page/header-page';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Button } from 'components/ui/Button';

import { SidenavSteps } from 'components/sidenav-message/steps/steps';

import { DocumentHeaderInfo } from 'components/document-header-info';
import { Loader } from 'components/loader/loader';
import { Formik, FormikValues } from 'formik';
import { useGetCoreDocumentsQuery } from 'store/api/documents';
import { useAppDispatch } from 'store/hooks';
import { setFuelFormData } from 'store/reducers/contract.reducer';
import { prepareDate } from 'utils/date';
import {
  SchemeModificators,
  generateForm,
  generateInitialValues,
} from 'utils/sheme-parser/generate-form';
import { Header } from '../header';

import { useEffect } from 'react';
import { LICENCE_TEMPLATE_ID } from 'store/api/queries/documents/get.document.licences.scheme';
import styles from './NewContract.module.scss';
import { useNewContract } from './use-new-contract';

const FormResetter = ({
  resetForm,
  setStep,
  currentSelect,
}: {
  resetForm: () => void;
  setStep: (step: number) => void;
  currentSelect: { value: string; label: string };
}) => {
  useEffect(() => {
    resetForm();
    setStep(1);
  }, [currentSelect]);

  return null;
};

export const NewContract = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const withHeader = true;
  const withFooter = true;

  const {
    step,
    steps,
    model,
    setStep,
    stepsLabels,
    isApproval,
    currentSelect,
    setCurrentSelect,
    dropDownOptions,
    openDocumentSign,
    createFuelDocument,
    createAgroDocument,
    getValidationSchema,
    createLicenseDocument,
  } = useNewContract();

  const { data: [document] = [], isFetching } = useGetCoreDocumentsQuery(
    { ids: [id ?? ''] },
    { skip: !id },
  );

  const handlePrevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const onSubmit = async (values: FormikValues) => {
    if (step < steps[steps?.length - 1]) {
      setStep(step + 1);
    } else {
      if (isApproval && id) {
        openDocumentSign(id);
      }

      const modifiedValues = { ...values };
      const parameters: any = [];

      Object.keys(modifiedValues)?.forEach((key) => {
        if (key?.toLocaleLowerCase()?.includes('date')) {
          modifiedValues[key] = prepareDate(modifiedValues[key]);
        }
        if (
          currentSelect.scheme.find((item: any) => item.name === key).fieldTransform ===
          'parameter'
        ) {
          parameters.push({
            key: key,
            valStr: modifiedValues[key],
          });
          delete modifiedValues[key];
        }
      });

      dispatch(setFuelFormData(modifiedValues));

      if (currentSelect.value === 'Fuel') {
        await createFuelDocument({
          input: {
            ...modifiedValues,
          },
        });
      }
      if (currentSelect.value === 'License') {
        await createLicenseDocument({
          input: {
            documentTemplateId: LICENCE_TEMPLATE_ID.Sublicense,
            ...modifiedValues,
            parameters,
          },
        });
      }
      if (currentSelect.value === 'Partner') {
        await createLicenseDocument({
          input: {
            documentTemplateId: LICENCE_TEMPLATE_ID.Partner,
            ...modifiedValues,
            parameters,
          },
        });
      }
      if (currentSelect.value === 'AgroSupply') {
        await createAgroDocument({
          input: {
            ...modifiedValues,
            parameters: parameters?.length ? parameters : undefined,
          },
        });
      }
    }
  };

  const isSchemeExist = () => {
    return dropDownOptions?.reduce((acc, item) => {
      if (item.scheme) {
        return true;
      }
      return acc;
    }, false);
  };

  if (isFetching || !isSchemeExist()) {
    return <Loader size="medium" />;
  }

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={getValidationSchema()}
      initialValues={generateInitialValues(model)}
    >
      {({ submitForm, resetForm }) => (
        <div className={styles['container']}>
          <FormResetter currentSelect={currentSelect} resetForm={resetForm} setStep={setStep} />
          {withHeader && !isApproval && <HeaderPage title={t('New contract')} />}
          {withHeader && isApproval && <DocumentHeaderInfo isLongTitle document={document} />}

          <div className={styles.main}>
            {withHeader && (
              <Header
                disabled={isApproval}
                options={dropDownOptions}
                currentSelect={currentSelect}
                setCurrentSelect={(value) => setCurrentSelect(value ?? dropDownOptions[0])}
              />
            )}
            <div className={styles['content']}>
              <SidenavSteps
                setStep={setStep}
                step={step}
                labels={stepsLabels}
                steps={steps?.length}
              />
              <div className={styles['form']}>
                {generateForm(model, step, isApproval ? [SchemeModificators.disabled] : [])}
              </div>
            </div>
            {withFooter && (
              <div className={styles['footer']}>
                {step > 1 && (
                  <Button
                    className={cx(styles['button'], styles['button-back'])}
                    onClick={handlePrevStep}
                  >
                    {t('Back')}
                  </Button>
                )}
                <Button className={cx(styles['button'])} onClick={submitForm}>
                  {t('Next')}
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </Formik>
  );
};
