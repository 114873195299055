import cx from 'classnames';
import { Formik, FormikErrors, FormikValues } from 'formik';

import { useTranslation } from 'react-i18next';

import { AttorneyAddRepresentiveFormValues, initialValues, validationSchema } from './config';

import { RepresentativeType } from 'constants/attributes';

import { model } from 'components/attorney/forms/config';

import { IndividualEntrepreneurGroup } from 'components/forms/representative/individual-entrepreneur/individual-entrepreneur';
import { RepresentativeInformation } from 'components/forms/representative/information-ndividual/representative-information';
import { PaymentProcedureGroup } from 'components/forms/representative/payment-procedure/payment-procedure';

import { RadioGroupField } from 'components/fields/radio-group-field/select-filed';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import { Button } from 'components/ui/Button';

import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useCreatePoaRepresentativeMutation } from 'store/api/poaUsers';
import { useHandleMutation } from 'utils/handleMutation';

import { useGetByIdPoaUserQuery } from 'store/api/poaUsers';

import { prepareDate } from 'utils/date';
import s from './aside-representative.module.scss';

export const AsideRepresentative = ({
  isOpen,
  mainValues,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
  mainValues?: FormikValues;
}) => {
  const alert = useAlert();
  const { t } = useTranslation();

  const { data } = useGetByIdPoaUserQuery(
    { userId: mainValues?.representative },
    { skip: !mainValues?.representative },
  );

  const [createRepresentative, mutation] = useCreatePoaRepresentativeMutation();

  const onSuccess = useCallback(() => {
    alert.success(t('Representative added'));
    if (handleClose) {
      handleClose();
    }
  }, [alert.success]);

  const onError = useCallback(() => {
    alert.error(t('Representative not added'));
  }, [alert.error]);

  useHandleMutation({ ...mutation, onSuccess, onError });

  const containerClasses = cx(s['container'], {
    [s['open']]: isOpen,
  });
  const wrapperClasses = cx(s['wrapper'], {
    [s['open']]: isOpen,
  });
  const classesButtonBack = cx(s['button'], s['button-back']);
  const classesButtonSave = cx(s['button'], s['button-save']);

  const firstOption = model.typeRepresentative.options[0];

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      if (handleClose) {
        handleClose();
      }
    }
  };

  const onSubmit = async (values: AttorneyAddRepresentiveFormValues) => {
    const {
      inn,
      ogrn,
      ogrnip,
      paymentProcedure,
      kppAddingRepresentative,
      nameCompanyAddingRepresentative,
    } = values;

    const {
      name,
      innFl,
      snils,
      surname,
      middleName,
      dateIssueDocument,
      typeRepresentative,
      typeIdentityDocument,
      birthdateRepresentative,
      documentSeriesAndNumber,
      citizenshipRepresentative,
      nameAuthorityIssuedDocument,
    } = values;

    const isRepresentativeSoleProprietor =
      typeRepresentative === RepresentativeType.SoleProprietor;
    const isRepresentativeLegalEntity = typeRepresentative === RepresentativeType.LegalEntity;

    const name_egrip = isRepresentativeSoleProprietor
      ? nameCompanyAddingRepresentative
      : undefined;
    const inn_legal_entity = isRepresentativeLegalEntity ? inn : undefined;

    await createRepresentative({
      snils,
      userType: typeRepresentative,
      request: JSON.stringify({
        inn,
        ogrn,
        ogrnip,
        name_egrip,
        name: name,
        snils: snils,
        surname: surname,
        inn_legal_entity,
        doc_code: '000000',
        inn_natural_person: innFl,
        paternal_name: middleName,
        kpp: kppAddingRepresentative,
        doc_type: typeIdentityDocument,
        doc_number: documentSeriesAndNumber,
        doc_issuer: nameAuthorityIssuedDocument,
        doc_issue_date: prepareDate(dateIssueDocument),
        birth_date: prepareDate(birthdateRepresentative),
        organization_name: nameCompanyAddingRepresentative,
        citizenship_flag: 'ru' ?? citizenshipRepresentative,
      }),
    });
  };

  const scrollToError = (errors: FormikErrors<AttorneyAddRepresentiveFormValues>) => {
    const allErrorKeys = Object.keys(errors);
    let foundFirstError = false;

    allErrorKeys.forEach((error) => {
      const errorElement = document.querySelector(`[name="${error}"]`);

      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth' });
        foundFirstError = true;
        return;
      }

      const selectElement = document.getElementById(`${error}`);

      if (selectElement) {
        selectElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
        foundFirstError = true;
      }
    });
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema({ t })}
    >
      {({ dirty, isSubmitting, submitForm, errors, values }) => (
        <div className={containerClasses}>
          <div className={wrapperClasses}>
            <div className={s['header']}>
              <span className={s['title']}>{t('Add authority')}</span>

              <div
                role="button"
                tabIndex={0}
                className={s['close']}
                onClick={handleClose}
                onKeyDown={handleKeyDown}
              >
                <CloseIcon />
              </div>
            </div>

            <div className={s['main']}>
              <div className={s['scroll']}>
                <RadioGroupField
                  variant="column"
                  {...model.typeRepresentative}
                  value={{
                    label: t(firstOption.label),
                    value: firstOption.value,
                  }}
                />
                {values?.typeRepresentative === RepresentativeType?.NaturalPerson ? null : (
                  <>
                    {values?.typeRepresentative === RepresentativeType?.LegalEntity && (
                      <PaymentProcedureGroup />
                    )}
                    {values?.typeRepresentative === RepresentativeType?.SoleProprietor && (
                      <IndividualEntrepreneurGroup />
                    )}
                  </>
                )}
                <RepresentativeInformation values={values} />
              </div>
            </div>

            <div className={s['footer']}>
              <Button
                displayType="outlined-secondary"
                className={classesButtonBack}
                onClick={handleClose}
              >
                {t('Back')}
              </Button>
              <Button
                disabled={!dirty || isSubmitting}
                className={classesButtonSave}
                onClick={() => {
                  submitForm().then(() => scrollToError(errors));
                }}
              >
                {t('Save')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
